import axios from "axios"
import qs from "qs"
import pluralize from "pluralize"
import moment from "moment"

const ENDPOINT = process.env.ENDPOINT
const API_ENDPOINT = process.env.API_ENDPOINT

export function getApiUrl ( endpoint ) {
	return `${API_ENDPOINT}/v2/${endpoint.replace (/^\/+/, "")}`
}

export function getSubDomainUrl ( subDomain ) {
	return `//${API_ENDPOINT.replace(/^[^.-]+/, subDomain)}`
}

export function setRedirect ( override = false ) {
	if ( /\bredirect=/.test ( document.cookie ) && !override ) return
	document.cookie = `redirect=${ENDPOINT}; domain=jetrails.com; path= /;`
}

export function setPromoCode ( code ) {
	document.cookie = `promo=${code}; domain=jetrails.com; path= /;`
}

export function request ({ endpoint, ...extra }) {
	return axios ({
		method: "get",
		url: getApiUrl ( endpoint ),
		withCredentials: true,
		headers: { "Content-Type": "application/json" },
		paramsSerializer: params => qs.stringify ( params ),
		...extra,
	})
	.then ( response => response?.data || null )
	.catch ( error => error?.response?.data || null )
}

export function getNormalizationFactor ( instanceType ) {
	const parts = instanceType.replace (/\.(elastic)?search$|^cache\.|^db\./g, "").split (".")
	switch ( parts?.[1] ) {
		case "nano":
			return 0.25
		case "micro":
			return 0.5
		case "small":
			return 1
		case "medium":
			return 2
		case "large":
			return 4
		case "xlarge":
			return 8
		case "2xlarge":
			return 16
		case "4xlarge":
			return 32
		case "8xlarge":
			return 64
		case "10xlarge":
			return 80
		case "16xlarge":
			return 128
		case "24xlarge":
			return 192
		case "32xlarge":
			return 256
		case "48xlarge":
			return 384
		default:
			return null
	}
}

export function getTermFromSeconds ( unix ) {
	const duration = moment.duration ( ( unix ) * 1000 )
	var result = ""
	var carry = duration.asSeconds ()
	if ( carry % 60 > 0 ) result = `${carry % 60} ${pluralize ( "Sec", carry % 60 )} ${result}`
	carry = Math.floor ( carry / 60 )
	if ( carry % 60 > 0 ) result = `${carry % 60} ${pluralize ( "Min", carry % 60 )} ${result}`
	carry = Math.floor ( carry / 60 )
	if ( carry % 24 > 0 ) result = `${carry % 24} ${pluralize ( "Hour", carry % 24 )} ${result}`
	carry = Math.floor ( carry / 24 )
	if ( carry % 365 > 0 ) result = `${carry % 365} ${pluralize ( "Day", carry % 365 )} ${result}`
	carry = Math.floor ( carry / 365 )
	if ( carry > 0 ) result = `${carry} ${pluralize ( "Year", carry )} ${result}`
	return result
}

export function zendeskSupportLink ({ subject = "", description = "" }) {
	const base = "https://autopilot-support.jetrails.com/hc/en-us/requests/new?"
	const params = {
		ticket_form_id: 24616540159245,
		tf_24616754849549: "AutoPilot",
		tf_subject: subject,
		tf_description: description,
	}
	return base + qs.stringify ( params )
}
