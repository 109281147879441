import "@fontsource/roboto"
import { createTheme } from "@mui/material/styles"
import { teal } from "@mui/material/colors"

const base = createTheme ({ palette: { mode: "light" } })
const { augmentColor } = base.palette

export default createTheme ({
	typography: {
		fontFamily: "Roboto",
		fontSize: 14,
	},
	palette: {
		primary: augmentColor({ color: { main: "#000000" } }),
		mode: base.palette.mode,
		dark: augmentColor ({ color: { main: "#282F38" } }),
		green: augmentColor ({ color: { main: "#89BA40" } }),
		transparent: augmentColor ({ color: { main: "#FFFFFF00" } }),
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					height: "100%",
					width: "100%",
				},
				body: {
					margin: 0,
					height: "100%",
					width: "100%",
				},
				"#main": {
					height: "100%",
					width: "100%",
				},
				"::selection": {
					background: "#282F38",
					color: "#FFFFFF",
				},
				"input::-webkit-outer-spin-button": {
					appearance: "none",
					margin: 0,
				},
				"input::-webkit-inner-spin-button": {
					appearance: "none",
					margin: 0,
				},
			},
		},
		MuiSvgIcon: {
			defaultProps: {
				color: "inherit",
				sx: {
					color: t => t.palette.text.primary,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				hiddenLabel: {
					paddingBottom: base.spacing ( 1 ),
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					padding: 2,
					borderRadius: 2,
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
				},
			},
			defaultProps: {
				disableUnderline: true,
				margin: "dense",
			},
		},
		MuiDataGrid: {
			styleOverrides: {
				row: {
					"&.Mui-selected": {
						backgroundColor: base.palette.divider,
					},
				},
			},
		},
	},
})
