import moment from "moment"
import { useState } from "react"
import { Link, DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"
import { getApiUrl, setRedirect } from "services/utilities"
import { useCache } from "contexts/CacheContext"
import bff, { api } from "services/unprivileged"

export default function AcceptTermsOfService () {

	const [response, setResponse] = useState (null)
	const { useCacheEffect } = useCache ()
    const session = useCacheEffect ( bff.getSession )

	const isLoading = session.isLoading
	const canShow = !session.isLoading && session.get ("code") === 200
	const latestVersion = session.get ("message")
	const agreedVersion = session.get ("payload.agreedVersion")
	const agreedAt = session.get ("payload.agreedAt")
	const shouldShow = agreedVersion === null || agreedVersion !== latestVersion
	const isResponseLoading = response === false

	function onCancel () {
		setRedirect ()
		window.location = getApiUrl ("auth/logout")
	}

	async function onAccept () {
		setResponse ( false )
		const res = await api.acceptTos ()
		setResponse ( res )
		session.evict ()
	}

	return <Dialog open={canShow && shouldShow} >
		<DialogTitle>Terms of Service</DialogTitle>
		<DialogContent dividers >
			<DialogContentText color="textPrimary" >
				{
					!!agreedAt && <>
						{`Our terms of service have been updated since your last acceptance on ${moment(agreedAt || 0).format("L")}.`}
						<br /><br />
					</>
				}
				{"By clicking \"ACCEPT\" you agree to the "}
				<Link target="_blank" color="green.main" href="https://jetrails.com/terms-of-service" >Terms of Service</Link> 
				{"."}
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button disabled={isLoading || isResponseLoading} onClick={onCancel} >Cancel</Button>
			<Button disabled={isLoading || isResponseLoading} onClick={onAccept} color="green" type="submit" >Accept</Button>
		</DialogActions>
	</Dialog>

}