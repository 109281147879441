import { Paper, Avatar, Stack, Button, Typography, Divider } from "@mui/material"
import AdminPortalIcon from "assets/adf-brandmark.svg"
import ClientPortalIcon from "assets/autopilot-brandmark.svg"
import SupportPortalIcon from "assets/autopilot-support.svg"
import UserIcon from "@mui/icons-material/Person"
import SignOffIcon from "@mui/icons-material/ExitToApp"
import { useCache } from "contexts/CacheContext"
import { getApiUrl, setRedirect } from "services/utilities"
import bff from "services/unprivileged"
import { Fragment, useState } from "react"
import { List, ListItemButton, ListItemAvatar, ListItemText } from "@mui/material"
import RightIcon from "@mui/icons-material/ArrowForward"

function changeLocation ( url ) {
	setTimeout ( () => window.location = url, 500 )
}

function SessionInfo ( props ) {

	const [clicked, setClicked] = useState ( false )
	const { useCacheEffect } = useCache ()
	const session = useCacheEffect ( bff.getSession )

	return <Paper
		component={Stack}
		spacing={1}
		sx={{ p: 2, borderRadius: 3 }} >
		<Stack spacing={2} justifyContent="space-between" alignItems="center" >
			<Avatar variant="circular" sx={{ width: 65, height: 65, bgcolor: t => t.palette.dark.main }} >
				{
					!!session.get ("payload.picture", "") && <img
						alt="profile-picture"
						width="100%"
						height="100%"
						src={session.get ("payload.picture")}
					/>
				}
				{
					!session.get ("payload.picture") && <UserIcon fontSize="large" sx={{ color: "inherit" }} />
				}
			</Avatar>
			<Stack justifyContent="center" alignItems="center" >
				<Typography component="p" variant="h5" >{session.get ("payload.name") || session.get ("payload.email")}</Typography>
				{session.get ("payload.name") && <Typography variant="subtitle" color="textSecondary" sx={{ }} >{session.get ("payload.email")}</Typography>}
			</Stack>
		</Stack>
		<List sx={{ ml: t => `${t.spacing (-2)} !important`, mr: t => `${t.spacing (-2)} !important` }} >
			<Divider component="li" />
			{
				session.get ("payload.portals", [])?.map ( portal =>
					<Fragment key={portal.title} >
						<ListItemButton
							disabled={clicked}
							disableTouchRipple
							disableRipple
							sx={{ pl: 4, pr: 4 }}
							onClick={() => { setClicked ( true ); changeLocation ( portal.link ) }} >
							<ListItemAvatar>
								<Avatar
									variant="square"
									alt={portal.title}
									sx={{ "& img": { objectFit: "contain" } }}
									src={{
										admin: AdminPortalIcon,
										client: ClientPortalIcon,
										support: SupportPortalIcon,
									} [ portal.icon ]}
								/>
							</ListItemAvatar>
							<ListItemText
								primary={portal.title}
								secondary={portal.description}
								primaryTypographyProps={{ variant: "h6" }}
								secondaryTypographyProps={{ color: "textSecondary" }}
							/>
							<RightIcon/>
						</ListItemButton>
						<Divider component="li" />
					</Fragment>
				)
			}
		</List>
		<Button
			disableRipple
			disableTouchRipple
			disabled={clicked}
			sx={{ color: "inherit" }}
			variant="outlined"
			endIcon={<SignOffIcon sx={{ color: "inherit" }} />}
			onClick={e => {
				setRedirect ()
				setClicked ( true )
				changeLocation ( getApiUrl ("auth/logout") )
			}} >
			Sign Out
		</Button>
	</Paper>

}

export default SessionInfo
