import { Outlet, useSearchParams } from "react-router-dom"
import { Box, Link, Fade, Stack, Alert, Typography } from "@mui/material"
import { useMediaQuery } from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"
import LogoSrc from "assets/autopilot-accounts.svg"
import WavesSrc from "assets/waves.svg"
import JetRailsLogoSrc from "assets/logo.svg"
import AcceptTermsOfService from "components/AcceptTermsOfService"

function AccountPage ( props ) {

	const [searchQuery] = useSearchParams ()
	const isSmallScreen = useMediaQuery ( theme => theme.breakpoints.down ("sm") )

	return <Box sx={{
			py: 3,
			width: "100%",
			height: "100%",
			overflow: "auto",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			backgroundImage: `url(${WavesSrc})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
		}} >
		<AcceptTermsOfService/>
		<Fade in={!isSmallScreen} timeout={isSmallScreen ? 500 : 2000} >
			<Stack sx={{ position: "fixed", top: 16, left: 16 }} >
				<a href="https://jetrails.com" target="_blank" >
					<img src={JetRailsLogoSrc} style={{ width: 100 }} />
				</a>
			</Stack>
		</Fade>
		<Stack
			spacing={3}
			direction="column"
			sx={{
				marginTop: "auto",
				marginBottom: "auto",
				width: "100%",
				maxWidth: 480,
				p: { xs: 2, sm: 0 },
			}} >
			<Fade in={true} timeout={750} mountOnEnter unmountOnExit >
				<Stack alignItems="center" >
					<img
						src={LogoSrc}
						style={{
							paddingBottom: 16,
							width: "100%",
							maxWidth: 350,
						}}
					/>
				</Stack>
			</Fade>
			<Fade
				in={searchQuery.has ("invite") || searchQuery.has ("google") || searchQuery.has ("github") || searchQuery.has ("magic")}
				timeout={1000}
				mountOnEnter
				unmountOnExit >
				<Stack spacing={1} >
				{
					searchQuery.has ("invite") && <Alert
						sx={{ borderRadius: 3 }}
						icon={<ErrorIcon fontSize="inherit" sx={{ color: "error" }} />}
						severity="error" >
						{
							searchQuery.get ("invite") === "not-found" && "Requested invitation could not be found!"
						}
						{
							searchQuery.get ("invite") === "missmatch" && "Could not accept invitation because of email missmatch!"
						}
						{
							searchQuery.get ("invite") === "invalid" && "Malformed request to accept invitation!"
						}
						{
							!["not-found", "missmatch", "invalid"].includes ( searchQuery.get ("invite") ) && "Failed to accept organization invitation!"
						}
					</Alert>
				}
				{
					searchQuery.get ("google") === "fail" && <Alert
						sx={{ borderRadius: 3 }}
						icon={<ErrorIcon fontSize="inherit" sx={{ color: "error" }} />}
						severity="error" >
						Failed to authenticate with your Google account!
					</Alert>
				}
				{
					searchQuery.get ("github") === "fail" && <Alert
						sx={{ borderRadius: 3 }}
						icon={<ErrorIcon fontSize="inherit" sx={{ color: "error" }} />}
						severity="error" >
						Failed to authenticate with your Github account!
					</Alert>
				}
				{
					searchQuery.get ("magic") === "fail" && <Alert
						sx={{ borderRadius: 3 }}
						icon={<ErrorIcon fontSize="inherit" sx={{ color: "error" }} />}
						severity="error" >
						Invalid, expired, or already used magic link!
					</Alert>
				}
				</Stack>
			</Fade>
			<Fade in={true} timeout={1500} mountOnEnter unmountOnExit >	
				<Box><Outlet/></Box>
			</Fade>
			<Fade in={true} timeout={2000} >
				<Stack direction={{ xs: "column", sm: "row" }} justifyContent="center" alignItems="center" spacing={{ xs: 0, sm: 1 }} >
					<Typography variant="overline" color="white" lineHeight="1em" >
						By proceeding, you agree to the
					</Typography>
					<Stack direction="row" justifyContent="center" alignItems="center" spacing={1} >
						<Link variant="overline" target="_blank" href="https://jetrails.com/terms-of-service" color="#FFFFFF" >
							Terms Of Service
						</Link>
						<Typography variant="overline" color="white" lineHeight="1em" >
							&
						</Typography>
						<Link variant="overline" target="_blank" href="https://jetrails.com/privacy-policy" color="#FFFFFF" >
							Privacy Policy
						</Link>
					</Stack>
				</Stack>
			</Fade>
		</Stack>
	</Box>

}

export default AccountPage
