import moment from "moment"
import { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import MagicIcon from "@mui/icons-material/AutoAwesome"
import Alert from "@mui/material/Alert"
import ErrorIcon from "@mui/icons-material/Error"
import BackIcon from "@mui/icons-material/ArrowBack"
import { useParams, useNavigate } from "react-router-dom"
import { getApiUrl } from "services/utilities"

function MagicLanding ( props ) {

	const params = useParams ()
	const navigate = useNavigate ()
	const { token } = params
	const [ email, setEmail ] = useState ( false )
	const [ expiration, setExpiration ] = useState ( null )
	const isExpired = expiration === null || expiration.isBefore ( moment () )
	const isUnknown = email === null
	const validToken = !isUnknown && !isExpired

	useEffect (() => {
		try {
			const encoded = token.split (".")?.[1]
			const decoded = JSON.parse ( atob ( encoded ) )
			const extractedEmail = decoded?.user?.email
			const extractedExpiration = moment.unix ( decoded?.exp )
			setEmail ( extractedEmail )
			setExpiration ( extractedExpiration )
		}
		catch ( error ) {
			console.error ( error )
			setEmail ( null )
		}
	}, [ token ])

	return <Paper
		elevation={2}
		sx={{ p: { xs: 2, sm: 2 }, borderRadius: 3 }}
		component={Stack}
		spacing={1} >
		<Typography component="h1" variant="h5" align="center" sx={{ pt: 2 }} >
			Confirm your magic sign-in
		</Typography>
		{
			validToken && <Typography sx={{ pt: 2 }} >
				You're signing in as <u><b>{email}</b></u>. Confirm sign-in by clicking the button below.
			</Typography>
		}
		{
			validToken && <Typography sx={{ pt: 2 }} >
				This sign-in request will expire at <b>{expiration.format ("h:mm:ss A")}</b>.
			</Typography>
		}
		{
			!validToken && <Alert
				icon={<ErrorIcon fontSize="inherit" sx={{ color: "error" }} />}
				severity="error"
				sx={{ mt: t => `${t.spacing (3)} !important` }} >
				{
					( !isUnknown && expiration !== null && isExpired )
					? `This magic link expired ${expiration.fromNow ()}. Please try again.`
					: "Malformed token was detected. Please try again."
				}
			</Alert>
		}
		<Box sx={{ py: 1 }} >
			<Divider/>
		</Box>
		{
			!validToken && <Button
				fullWidth
				variant="outlined"
				onClick={() => navigate ("/") }
				startIcon={<BackIcon/>} >
				Back To Sign-In Page
			</Button>
		}
		{
			validToken && <Button
				fullWidth
				variant="outlined"
				disabled={!validToken}
				onClick={() => window.location = getApiUrl (`auth/magic/callback?token=${token}`) }
				startIcon={<MagicIcon/>} >
				Continue
			</Button>
		}
	</Paper>
}

export default MagicLanding
