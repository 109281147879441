import { useState } from "react"
import Fade from "@mui/material/Fade"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import GoogleSrc from "assets/google-icon.svg"
import GithubSrc from "assets/github-icon.svg"
import MagicIcon from "@mui/icons-material/AutoAwesome"
import TextField from "@mui/material/TextField"
import { getApiUrl, setRedirect } from "services/utilities"
import { hasFieldError, getFieldError } from "services/validation"
import { api } from "services/unprivileged"
import { Divider } from "@mui/material"
import { useNavigate } from "react-router-dom"

function SignIn ( props ) {

	const navigate = useNavigate ()
	const [ email, setEmail ] = useState ("")
	const [response, setResponse] = useState (null)
	const isLoading = response === false
	const opacity = isLoading ? 0.5 : 1

	async function onMagicLinkClick () {
		setRedirect ()
        setResponse ( false )
        const r = await api.requestMagicLink ({ email })
        setResponse ( r )
		if ( r?.code === 200 ) {
			return navigate ("/magic/sent")
		}
	}

	return <Stack spacing={3} >
		<Fade in={true} timeout={1000} mountOnEnter unmountOnExit >
			<Paper elevation={2} sx={{ p: { xs: 2, sm: 2 }, borderRadius: 3 }} >
				<Typography component="h1" variant="h5" align="center" sx={{ pt: 2, pb: 3 }} >
					Sign-up or sign-in to your account
				</Typography>
				<Stack spacing={1} >
					<Stack spacing={{ xs: 1.5, sm: 1 }} direction={{ xs: "column", sm: "row" }} >
						<Button
							fullWidth
							disabled={isLoading}
							variant="outlined"
							color="dark"
							onClick={() => setResponse ( false ) || setRedirect ()}
							href={getApiUrl ("auth/google")}
							startIcon={<img alt="google" style={{ height: 18, width: 18, opacity }} src={GoogleSrc} />} >
							Continue with Google
						</Button>
						<Button
							fullWidth
							disabled={isLoading}
							variant="outlined"
							color="dark"
							onClick={() => setResponse ( false ) || setRedirect ()}
							href={getApiUrl ("auth/github")}
							startIcon={<img alt="github" style={{ height: 18, width: 18, opacity }} src={GithubSrc} />} >
							Continue with Github
						</Button>
					</Stack>
					<Stack spacing={{ xs: 1.5, sm: 1 }} >
						<Stack direction="row" spacing={1} alignItems="center" >
							<Divider sx={{ flex: 1 }} />
							<Typography align="center" variant="overline" sx={{ opacity }} >
								or
							</Typography>
							<Divider sx={{ flex: 1 }} />
						</Stack>
						<TextField
							autoFocus
							size="small"
							type="email"
							variant="outlined"
							sx={{ borderRadius: 0 }}
							label="Email Address"
							value={email}
							disabled={isLoading}
							onChange={e => setEmail ( e.target.value ) || setResponse ( null )}
							onKeyUp={e => e.key === "Enter" && email.length > 5 && onMagicLinkClick ()}
							error={hasFieldError ( response, "email" )}
							helperText={getFieldError ( response, "email" )}
						/>
						<Button
							fullWidth
							disabled={isLoading}
							variant="outlined"
							color="dark"
							onClick={() => onMagicLinkClick ()}
							startIcon={<MagicIcon sx={{ height: 18, width: 18, opacity }} />} >
							Continue with Email
						</Button>
					</Stack>
				</Stack>
			</Paper>
		</Fade>
	</Stack>

}

export default SignIn
