import { request } from "services/utilities"
import { makeMemoizable } from "contexts/CacheContext"
import Promise from "bluebird"

export const api = {
	getSession () {
		return request ({ endpoint: "auth/session" })
	},
	acceptTos () {
		return request ({ method: "post", endpoint: "auth/accept-tos" })
	},
	requestMagicLink ({ email }) {
		return request ({ method: "post", endpoint: "auth/magic", data: { email } })
	},
	getClientConnection () {
		return request ({
			endpoint: "",
			url: "https://ip.jetrails.com",
			withCredentials: false,
			headers: { "accept": "application/json" },
		})
	},
	getOrganizations({ withLegacy = true, onlyMemberships = false, page, perPage, sortField, sortDirection, filter }) {
		return request ({ endpoint: "organizations", params: { page, perPage, sortField, sortDirection, filter, withLegacy, onlyMemberships } })
	},
	getActivityForOrganization({ page = 0, pageSize = 32, organizationId }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({ endpoint: `organizations/${organizationId}/activity`, params: { page, pageSize } })
	},
	getActivityForDeployment({ page = 0, pageSize = 32, sortField = "createdAt", sortDirection = "desc", deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `deployments/${deploymentId}/activity`, params: { page, pageSize } })
	},
	getOrganization({ id }) {
		if ( id == null ) return Promise.resolve ()
		return request ({ endpoint: `organizations/${id}` })
	},
	createOrganization ({ ownerId, name }) {
		return request ({ method: "post", endpoint: "organizations", data: { ownerId, name } })
	},
	deleteOrganization ({ id }) {
		return request ({ method: "delete", endpoint: `organizations/${id}` })
	},
	renameOrganization ({ id, name }) {
		return request ({ method: "patch", endpoint: `organizations/${id}`, data: { name } })
	},
	getDeploymentsForOrganization ({ organizationId }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({
			endpoint: "deployments",
			params: {
				filter: [
					{ field: "organizationId", op: "equals", value: organizationId },
					{ field: "status", op: "doesNotEqual", value: "deleted" },
				],
			},
		})
	},
	getDeploymentAmis ({ id, input }) {
		if ( !id ) return Promise.resolve ()
		return request ({ endpoint: `deployments/${id}/amis`, params: { input } })
	},
	createDeployment ({ name, organizationId, baseTemplateId, data }) {
		return request ({ method: "post", endpoint: "deployments", data: { name, organizationId, baseTemplateId, data } })
	},
	updateDeployment ({ id, data }) {
		return request ({ method: "patch", endpoint: `deployments/${id}`, data: { data } })
	},
	renameDeployment ({ id, name }) {
		return request ({ method: "patch", endpoint: `deployments/${id}`, data: { name } })
	},
	deleteDeployment ({ id }) {
		return request ({ method: "delete", endpoint: `deployments/${id}` })
	},
	getDeploymentBackupResources ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `backups/resources`, params: { deploymentId } })
	},
	getDeploymentBackupRequests ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `backups/pending`, params: { deploymentId } })
	},
	getBackupsForDeployment ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `backups`, params: { deploymentId } })
	},
	triggerManualBackup ({ deploymentId, id, name }) {
		if ( deploymentId == null || id == null ) return Promise.resolve ()
		return request ({ method: "post", endpoint: `backups/resources/${id}`, params: { deploymentId }, data: { name } })
	},
	getTemplates ({ preferredApplication = null, preferredChannel = "stable", latestOnly = true }) {
		var filter = []
		if ( preferredChannel ) {
			filter = [
				{ field: "channel", op: "equals", value: preferredChannel },
			]
		}
		if ( preferredApplication ) filter.push ({ field: "application", op: "equals", value: preferredApplication })
		return request ({ endpoint: "templates", params: { sortBy: "application", latestOnly, filter } })
	},
	getTemplate ({ id }) {
		if ( id == null ) return Promise.resolve ()
		return request ({ endpoint: `templates/${id}` })
	},
	getDeployment ({ id }) {
		if ( id == null ) return Promise.resolve ()
		return request ({ endpoint: `deployments/${id}` })
	},
	getAutoScalingGroups ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `auto-scaling-groups`, params: { deploymentId } })
	},
	getAutoScalingGroup ({ deploymentId, name }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `auto-scaling-groups/${name}`, params: { deploymentId } })
	},
	updateAutoScalingGroup ({ deploymentId, name, ...data }) {
		return request ({ method: "patch", endpoint: `auto-scaling-groups/${name}`, params: { deploymentId }, data })
	},
	getAutoScalingGroupScheduledActions ({ deploymentId, name }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `auto-scaling-groups/${name}/scheduled-actions`, params: { deploymentId } })
	},
	getAutoScalingGroupScheduledAction ({ deploymentId, name, id }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `auto-scaling-groups/${name}/scheduled-actions/${id}`, params: { deploymentId } })
	},
	createAutoScalingGroupScheduledAction ({ deploymentId, name, ...data }) {
		return request ({ method: "post", endpoint: `auto-scaling-groups/${name}/scheduled-actions`, params: { deploymentId }, data })
	},
	updateAutoScalingGroupScheduledAction ({ deploymentId, name, id, ...data }) {
		return request ({ method: "patch", endpoint: `auto-scaling-groups/${name}/scheduled-actions/${id}`, params: { deploymentId }, data })
	},
	deleteAutoScalingGroupScheduledAction ({ deploymentId, name, id }) {
		return request ({ method: "delete", endpoint: `auto-scaling-groups/${name}/scheduled-actions/${id}`, params: { deploymentId } })
	},
	getTemplate ({ id }) {
		if ( id == null ) return Promise.resolve ()
		return request ({ endpoint: `templates/${id}` })
	},
	getCreditCards ({ organizationId }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({ endpoint: `credit-cards`, params: { organizationId } })
	},
	getCreditCard ({ organizationId, id }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({ endpoint: `credit-cards/${id}`, params: { organizationId } })
	},
	createCreditCard ({ organizationId, ...params }) {
		return request ({ method: "post", endpoint: `credit-cards`, data: { organizationId, ...params } })
	},
	updateCreditCard ({ organizationId, id, ...params }) {
		return request ({ method: "patch", endpoint: `credit-cards/${id}`, data: { organizationId, ...params } })
	},
	deleteCreditCard ({ organizationId, id }) {
		return request ({ method: "delete", endpoint: `credit-cards/${id}`, data: { organizationId } })
	},
	makeCreditCardDefault ({ organizationId, id }) {
		return request ({ method: "patch", endpoint: `credit-cards/${id}/default`, data: { organizationId } })
	},
	getWhitelistsForDeployment ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `deployments/${deploymentId}/whitelists` })
	},
	updateDeploymentWhitelists ({ deploymentId, data }) {
		return request ({ method: "patch", endpoint: `deployments/${deploymentId}/whitelists`, data: { data } })
	},
	getTopicsForDeployment ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `topics`, params: { deploymentId } })
	},
	subscribeToTopic ({ deploymentId, topicKey, email }) {
		return request ({ method: "post", endpoint: `topics/${topicKey}`, data: { deploymentId, email } })
	},
	unsubscribeFromTopic ({ deploymentId, topicKey, subscriptionId }) {
		return request ({ method: "delete", endpoint: `topics/${topicKey}`, data: { deploymentId, subscriptionId } })
	},
	getUserLogins ({ id }) {
		if ( id == null ) return Promise.resolve ()
		return request ({ endpoint: `users/${id}/logins`, params: { page: 1, perPage: 10 } })
	},
	getEntitlementsForUser ({ id }) {
		if ( id == null ) return Promise.resolve ()
		const filter = [
			{ field: "entity", op: "equals", value: "user" },
			{ field: "entityId", op: "equals", value: id },
		]
		return request ({ endpoint: `entitlements`, params: { filter } })
	},
	getEntitlementsForOrganization ({ id }) {
		if ( id == null ) return Promise.resolve ()
		const filter = [
			{ field: "entity", op: "equals", value: "organization" },
			{ field: "entityId", op: "equals", value: id },
		]
		return request ({ endpoint: `entitlements`, params: { filter } })
	},
	getInvoicesForOrganization ({ organizationId, additionalFilters = [] }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({ endpoint: `invoices`, params: { filter: [{ field: "organizationId", op: "equals", value: organizationId }, ...additionalFilters] } })
	},
	getInvoice ({ id }) {
		if ( id == null ) return Promise.resolve ()
		return request ({ endpoint: `invoices/${id}` })
	},
	payInvoiceInFull ({ organizationId, id, paymentId }) {
		if ( organizationId == null || id == null ) return Promise.resolve ()
		return request ({ method: "post", endpoint: `invoices/${id}/pay-in-full`, data: { organizationId, paymentId } })
	},
	getInvoiceTransactions ({ organizationId, id }) {
		if ( organizationId == null || id == null ) return Promise.resolve ()
		return request ({ endpoint: `invoices/${id}/transactions`, params: { organizationId } })
	},
	getInvoiceBillingAddress ({ id }) {
		if ( id == null ) return Promise.resolve ()
		return request ({ endpoint: `organizations/${id}/billing-address` })
	},
	setInvoiceBillingAddress ({ id, ...data }) {
		if ( id == null ) return Promise.resolve ()
		return request ({ method: "patch", endpoint: `organizations/${id}/billing-address`, data })
	},
	getCreditBalanceTransactions ({ organizationId }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({ endpoint: `credit-balances/${organizationId}` })
	},
	getSecurityDepositTransactions ({ organizationId }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({ endpoint: `security-deposits/${organizationId}` })
	},
	getCertificatesForOrganization ({ organizationId }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({ endpoint: `certificates`, params: { organizationId } })
	},
	getCertificateForOrganization ({ organizationId, id }) {
		if ( organizationId == null || id == null ) return Promise.resolve ()
		return request ({ endpoint: `certificates/${id}`, params: { organizationId } })
	},
	reImportCertificateForOrganization ({ organizationId, id, certificate, privateKey, certificateChain }) {
		return request ({ method: "patch", endpoint: `certificates/${id}`, data: { organizationId, certificate, privateKey, certificateChain } })
	},
	deleteCertificateForOrganization ({ organizationId, id }) {
		return request ({ method: "delete", endpoint: `certificates/${id}`, data: { organizationId } })
	},
	importCertificateForOrganization ({ organizationId, certificate, privateKey, certificateChain }) {
		return request ({ method: "post", endpoint: `certificates`, data: { organizationId, certificate, privateKey, certificateChain } })
	},
	getMembersForOrganization ({ organizationId }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({ endpoint: `members`, params: { organizationId } })
	},
	getInvitationsForOrganization ({ organizationId }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({ endpoint: `invitations`, params: { organizationId } })
	},
	inviteMemberToOrganization ({ organizationId, role, email }) {
		return request ({ method: "post", endpoint: `invitations`, data: { organizationId, role, email } })
	},
	cancelInvitation ({ id }) {
		return request ({ method: "delete", endpoint: `invitations/${id}`, data: {} })
	},
	changeMembership ({ id, role, receiveInvoice }) {
		return request ({ method: "patch", endpoint: `members/${id}`, data: { role, receiveInvoice } })
	},
	deleteMembership ({ id }) {
		return request ({ method: "delete", endpoint: `members/${id}`, data: {} })
	},
	updateUserProfile ({ id, phoneNumber, timezone, name }) {
		return request ({ method: "patch", endpoint: `users/${id}`, data: { phoneNumber, timezone, name } })
	},
	deactivateUser ({ id }) {
		return request ({ method: "delete", endpoint: `users/${id}`, data: {} })
	},
	getSshKeys ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `deployments/${deploymentId}/ssh-keys` })
	},
	deleteSshKey ({ deploymentId, groupName, fingerprint }) {
		return request ({ method: "delete", endpoint: `deployments/${deploymentId}/ssh-keys/${groupName}`, data: { fingerprint } })
	},
	addSshKey ({ deploymentId, groupName, publicKey, expiration, comment }) {
		return request ({ method: "post", endpoint: `deployments/${deploymentId}/ssh-keys/${groupName}`, data: { publicKey, expiration, comment } })
	},
	burnDeploymentSensitiveEntry ({ deploymentId, id }) {
		return request ({ method: "delete", endpoint: `deployments/${deploymentId}/sensitive-entries/${id}` })
	},
	sendFeedback ({ message }) {
		return request ({ method: "post", endpoint: `feedback`, data: { message } })
	},
	dismissDeploymentNotification ({ deploymentId, id }) {
		return request ({ method: "patch", endpoint: `deployments/${deploymentId}/notifications/${id}/dismiss` })
	},
	getDeploymentInstances ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `deployments/${deploymentId}/instances` })
	},
	getDeploymentMaintenanceWindow ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `deployments/${deploymentId}/maintenance-window` })
	},
	setDeploymentMaintenanceWindow ({ deploymentId, startAt }) {
		return request ({ method: "patch", endpoint: `deployments/${deploymentId}/maintenance-window`, data: { startAt } })
	},
	getDeploymentPatches ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `deployments/${deploymentId}/patches` })
	},
	updateDeploymentPatchStatus ({ deploymentId, patchId, status }) {
		return request ({ method: "patch", endpoint: `deployments/${deploymentId}/patches/${patchId}`, data: { status } })
	},
	changeInstanceState ({ id, instanceId, action }) {
		return request ({ method: "patch", endpoint: `deployments/${id}/instances/${instanceId}`, data: { action } })
	},
	executeAction ({ deploymentId, documentName, parameters = {} }) {
		return request ({ method: "post", endpoint: `deployments/${deploymentId}/actions/${documentName}`, data: parameters })
	},
	getDeploymentCommandOutput ({ deploymentId, id }) {
		if ( deploymentId == null || id == null ) return Promise.resolve ()
		const responses = Promise.map( id.split (","), id => request ({ method: "get", endpoint: `deployments/${deploymentId}/actions/${id}` }) )
		return responses.then ( responses => responses.reduce ( ( combined, response ) => {
			if ( combined == null ) return response
			combined.payload.items = [ ...combined.payload.items, ...response.payload.items ]
			return combined
		}, null ))
	},
	hibernateDeployment ({ id, enable, duration }) {
		if ( id == null ) return Promise.resolve ()
		return request ({ method: "patch", endpoint: `deployments/${id}/hibernate`, data: { enable, duration } })
	},
	getSnapshots ({ organizationId }) {
		if ( organizationId == null ) return Promise.resolve ()
		return request ({ endpoint: `backups/snapshots`, params: { organizationId } })
	},
	getImages ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `backups/images`, params: { deploymentId } })
	},
	getDeploymentAvailableAnalytics ({ deploymentId }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({ endpoint: `deployments/${deploymentId}/analytics/available` })
	},
	getDeploymentAnalytics ({ deploymentId, window, filterCategories = [], filterMetrics = [] }) {
		if ( deploymentId == null ) return Promise.resolve ()
		return request ({
			endpoint: `deployments/${deploymentId}/analytics`,
			params: {
				window,
				filterCategories,
				filterMetrics,
			},
		})
	},
	getPartnershipInfo ({ id }) {
		if ( id == null ) return Promise.resolve ()
		return request ({ endpoint: `organizations/${id}/partnership` })
	},
	submitPartnerLead ({ id, name, email, phone, company, url, launchDate, requestNote }) {
		return request ({ method: "post", endpoint: `organizations/${id}/partnership/leads`, data: { name, email, phone, company, url, launchDate, requestNote } })
	},
}

export default makeMemoizable ( api )
