import { Typography, Stack, Box } from "@mui/material"
import { useRouteError, isRouteErrorResponse } from "react-router-dom"

const NODE_ENV = process.env.NODE_ENV

export default function () {

	const error = useRouteError ()
	const isProduction = NODE_ENV === "production"

	return <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} >		
		<Stack spacing={1} alignContent="center" alignItems="center" >
			<Typography variant="h1" >{error?.status || "Whoops"}</Typography>
			<Typography variant="h4" color="textSecondary" sx={{ pb: 4 }} >{error?.statusText || "Something Happened..."}</Typography>
			{
				!isProduction && <Box
					component="pre"
					sx={{
						width: "100%",
						color: "error.light",
						border: t => `dashed 1px ${t.palette.error.main}`,
						p: 1,
						maxWidth: "80vw",
						whiteSpace: "break-spaces",
					}} >
					{isRouteErrorResponse ( error ) ? JSON.stringify ( error, null, 4 ) : error.toString ()}
				</Box>
			}
		</Stack>
	</Box>

}