import _ from "lodash"
import { createContext, useContext, useState, useEffect } from "react"

const NODE_ENV = process.env.NODE_ENV
const LoadingSymbol = Symbol ("loading")
const Cache = createContext ()

export const useCache = () => useContext ( Cache )

export function memoizable ( name, fn ) {
	const resolver = ( ...args ) => JSON.stringify ( args )
	return {
		name,
		cached: _.memoize ( fn, resolver ),
		uncached: fn,
	}
}

export function makeMemoizable ( obj ) {
	return _.mapValues ( obj, ( value, key ) => memoizable ( key, value ) )
}

export default ({ children }) => {
	const [ values, setValues ] = useState ({})
	if ( NODE_ENV !== "production" ) {
		console.log (`CacheContext:`, values)
	}
	const makeCacheKey = (fn, params) => {
		const message = "must pass named function to useCacheEffect since it's used to make a cache key"
		if ( !fn?.name ) {
			throw new Error ( message )
		}
		return `${fn?.name}|${btoa (JSON.stringify (params))}`
	}
	const setValuesWithOld = values => {
		return setValues ( current => ({ ...current, ...values }) )
	}
	const setValue = async (key, fn, params, silent = false) => {
		if ( !silent ) {
			setValues(current => ({ ...current, [key]: {params, response: LoadingSymbol} }) )
		}
		const response = await fn ( params )
		setValuesWithOld ({ [key]: { params, response, timestamp: new Date ().getTime () } })
	}
	const useEviction = fn => {
		return () => {
			setValues ( old => _.pickBy ( old, ( value, key ) => {
				const shouldEvict = key.startsWith (`${fn.name}|`)
				if ( shouldEvict ) {
					fn.cached?.cache?.clear ()
				}
				return !shouldEvict // Only keep entries that are not evicted
			}))
		}
	}
	const useCacheEffect = (fn, params = {}, dependencies) => {
		var cacheKey = makeCacheKey (fn, params)
		useEffect(() => {
			if ( !(cacheKey in values) ) {
				setValue(cacheKey, fn.cached, params)
			}
		}, dependencies )
		return {
			cacheKey,
			timestamp: values[cacheKey]?.timestamp || 0,
			params: values[cacheKey]?.params || params,
			value: values[cacheKey]?.response && values[cacheKey]?.response !== LoadingSymbol ? values[cacheKey]?.response : null,
			isLoading: values [ cacheKey ]?.response === LoadingSymbol,
			hasError: values [ cacheKey ]?.response !== LoadingSymbol && values [ cacheKey ]?.response?.code !== 200,
			hasSettled: values [ cacheKey ]?.response !== LoadingSymbol && values [ cacheKey ]?.timestamp > 0,
			get(path, defaults = null) {
				return _.get ( values [ cacheKey ]?.response, path, defaults )
			},
			refresh( silent = false ) {
				setValue (cacheKey, fn.uncached, values[cacheKey]?.params, silent)
			},
			update(params) {
				cacheKey = makeCacheKey(fn, params)
				setValue(cacheKey, fn.uncached, params)
			},
			mutate (mutator) {
				const previous = _.cloneDeep ( values[cacheKey] )
				const mutated = mutator ( values[cacheKey]?.response )
				const timestamp = new Date ().getTime ()
				setValuesWithOld ({ [cacheKey]: { params, response: mutated, timestamp } })
				return () => setValuesWithOld ({ [cacheKey]: previous })
			},
			evict () {
				useEviction ( fn ) ()
			}
		}
	}
	return <Cache.Provider value={{ useCacheEffect, useEviction }}>
		{children}
	</Cache.Provider>
}
