import _ from "lodash"
import { Route, Navigate, createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import { useNavigation } from "react-router-dom"
import SignIn from "sections/account/SignIn"
import SessionInfo from "sections/account/SessionInfo"
import ErrorBoundry from "components/ErrorBoundry"
import AccountPage from "components/AccountPage"
import MagicLanding from "sections/account/MagicLanding"
import MagicSent from "sections/account/MagicSent"
import { useCache } from "contexts/CacheContext"
import bff from "services/unprivileged"
import { CircularProgress, Stack } from "@mui/material"

function ValidateSession () {

	const { useCacheEffect } = useCache ()
	const session = useCacheEffect(bff.getSession)
	const { state } = useNavigation ()
	if ( state === "loading" || !session.hasSettled ) {
		return <Stack alignItems="center" >
			<CircularProgress color="green" />
		</Stack>
	}
	if ( session.get ("code") == 200 ) {
		return <SessionInfo/>
	}
	return <SignIn/>
}

export default createBrowserRouter (
	createRoutesFromElements (
		<Route path="/" element={<AccountPage/>} errorElement={<ErrorBoundry/>} >
			<Route index element={<ValidateSession/>} />
			<Route path="magic/sent" element={<MagicSent/>} />
			<Route path="magic/:token" element={<MagicLanding/>} />
			<Route path="*" element={<Navigate to="/" />} />
		</Route>
	)
)
