import ReactDOM from "react-dom"
import CssBaseline from "@mui/material/CssBaseline"
import theme from "themes/default"
import CacheProvider from "contexts/CacheContext"
import { ThemeProvider } from "@mui/material/styles"
import { RouterProvider } from "react-router-dom"
import router from "routers/accounts"

const NODE_ENV = process.env.NODE_ENV
if ( NODE_ENV !== "production" ) { console.log ( "MuiTheme:", theme ) }

ReactDOM.render (
	<ThemeProvider theme={theme} >
		<CacheProvider>
			<CssBaseline/>
			<RouterProvider router={router} />
		</CacheProvider>
	</ThemeProvider>,
	document.querySelector ("#main"),
)
