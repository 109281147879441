import _ from "lodash"

export function makeUserFriendly ( message ) {
	if ( /^"[^"]+"/.test ( message ) ) {
		fieldName = message.match ( /^"([^"]+)"/ )[1]
		message = message.replace(/^"[^"]+"/, `"${_.startCase ( fieldName )}"` )
	}
	return message
}

export function getFieldError ( response, fieldName, path = false ) {
	const value = response?.code == 422 ? makeUserFriendly(response?.payload?.[fieldName], fieldName) : undefined
	return path ? ( response?.payload?.[fieldName]?.startsWith (`"${path}"`) ? value : undefined ) : value
}

export function hasFieldError ( response, fieldName, path = false ) {
	const matchesField = response?.code == 422 && fieldName in ( response?.payload || {} )
	return path ? ( matchesField && response?.payload?.[fieldName]?.startsWith (`"${path}"`) ) : matchesField
}

export default {
	getFieldError,
	hasFieldError,
	makeUserFriendly,
}