import { Stack, Typography, Paper, Divider, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import BackIcon from "@mui/icons-material/ArrowBack"
import MagicIcon from "@mui/icons-material/AutoAwesome"

function MagicSent ( props ) {

	const navigate = useNavigate ()

	return <Paper
		elevation={2}
		component={Stack}
		spacing={3}
		align="center"
		sx={{ p: 2, borderRadius: 3 }} >
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="center"
			spacing={1.5}
			sx={{ pt: 2 }} >
			<MagicIcon/>
			<Typography component="h1" variant="h5" >
				Magic link has been sent
			</Typography>
		</Stack>
		<Typography>
			We emailed you a magic link.
			Click the link to sign-up or sign-in.
			Magic link will expire in <b>10 minutes</b> and can only be used once.
		</Typography>
		<Divider/>
		<Button
			fullWidth
			variant="outlined"
			onClick={() => navigate ("/") }
			startIcon={<BackIcon/>} >
			Back To Sign-In Page
		</Button>
	</Paper>

}

export default MagicSent
